export const menuItems = [];
const auth = window.localStorage.getItem("businessTypeId");
if (auth == 1) {
    menuItems.push(
        {
            id: 1,
            label: "Menu",
            isTitle: true
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'ri-dashboard-line',
            link: '/dashboard'
        },

        {
            id: 3,
            label: 'Invoice',
            icon: 'ri-menu-line',
            link: '/invoices'
        },
        {
            id: 4,
            label: 'Manage Services',
            icon: 'ri-briefcase-line',
            link: '/manage-services'
        },
        {
            id: 5,
            label: 'Health Hub Packs',
            icon: 'ri-police-car-fill',
            link: '/health-hub-package'
        },




    );
}
else if(auth == 2){
    menuItems.push(
        {
            id: 1,
            label: "Menu",
            isTitle: true
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'ri-dashboard-line',
            link: '/dashboard'
        },

        {
            id: 3,
            label: 'Invoice',
            icon: 'ri-menu-line',
            link: '/pharmacy-invoices'
        },
        {
            id: 4,
            label: 'Manage Medications',
            icon: 'ri-briefcase-line',
            link: '/manage-medication'
        },
        {
            id: 5,
            label: 'Health Hub Packs',
            icon: 'ri-police-car-fill',
            link: '/health-hub-package'
        },

    )
}
else if(auth == 3){
    menuItems.push(
        {
            id: 1,
            label: "Menu",
            isTitle: true
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'ri-dashboard-line',
            link: '/dashboard'
        },

        {
            id: 3,
            label: 'FoodHub Packs',
            icon: 'ri-menu-line',
            link: '/foodhub-packs'
        },
        

    )
}


